"use client";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// ANTD
import Button from 'antd/es/button';
import { LoadingOutlined } from '@ant-design/icons';

// THREE
import { group } from '../Three/nuggets';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import PortfolioCollection from './PortfolioCollection/PortfolioCollection';

// SERVICES
import { getCollectionsByOwner } from '../shared/services';

// INTERFACES
import { Nft, WineCollection } from '../shared/interfaces';

// STYLES
import './Portfolio.scss';

let init = false;

export default function Portfolio() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    let { accountId } = useParams();

    const [wineCollections, setWineCollections] = useState<WineCollection[]>();
    const [loader, setLoader] = useState<boolean>(true);

    const setAccountCollection = async (account: string): Promise<void> => {
        try {
            
            init = true;

            const collections = await getCollectionsByOwner(account);

            setWineCollections(collections)

            init = false
            setLoader(false);
            
        } catch (error) {
            alert(`error ${error}`, )
        }
    };

    useEffect(() => {
        group.visible = true;
        !init && location.pathname && setAccountCollection(location.pathname.substring(location.pathname.indexOf('/', 1) + 1, location.pathname.length));
    }, []);

    if (loader) {
        return (
            <div className="portfolio-container loading">
                <div className="portfolio-content">
                    <LoadingOutlined />
                </div>
            </div>
        )
    }
    if (!loader && wineCollections) {
        return (
            <div className="portfolio-container">
                <div className="portfolio-content">
                    {wineCollections.length ? (
                        wineCollections.map((wineCollection: WineCollection, i: number) => {
                            return <PortfolioCollection account={accountId!} wineCollection={wineCollection} key={i}/>
                        })
                    ) : (
                        <div className="empty-portfolio-container">
                            <h1>Your portfolio is empty</h1>
                            <p>Create your first Wine NFT Collection and become part of the community.</p>
                            <p>You will be able to sell your wine collection or buy wine from other members of the community.</p>
                            <Button className="mint-btn" onClick={() => navigate('/minter')}>Mint Collection</Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};