import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

// STYLES
import './ImageViewer.scss';

export default function ImageViewer({ imageUrl, imageError }: { imageUrl: string | undefined, imageError?: string }) {

    const [loader, setLoader] = useState<boolean>(true);
    const [image, setImage] = useState<string>();

    const setImageError = () => {
        setImage(imageError);
        setLoader(false);
    };

    useEffect(() => {
        imageUrl && setImage(imageUrl);
    }, [imageUrl]);

    return (
        <div className="image-viewer-container">
            <div className={"image-viewer-image " + (loader ? "loading": "")}>
                <Loading3QuartersOutlined spin/>
                <img src={image} onLoad={() => setLoader(false)} onError={() => setImageError()}/>
            </div>
        </div>
    );
    
};