export const sceneState = {
    position: { x: 0, y: -0.10, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
};

export const nuggetsState = [
    {
        glb: 'uva2.glb',
        position: { x: 0.5, y: 0.6, z: 118 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.17
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.5, y: -0.3, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.15
    },
    {
        glb: 'uva2.glb',
        position: { x: -0.9, y: 0.1, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.15
    },
    {
        glb: 'uva3.glb',
        position: { x: 0.6, y: -0.1, z: 118.6 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.12
    },
    {
        glb: 'uva2.glb',
        position: { x: -0.2, y: -1, z: 118.6 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.1
    },
    {
        glb: 'uva3.glb',
        position: { x: 0.5, y: -0.7, z: 118 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.22
    },
    {
        glb: 'uva2.glb',
        position: { x: -0.5, y: -1.6, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.2
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.9, y: -0.9, z: 118 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.12
    },
    {
        glb: 'uva2.glb',
        position: { x: 0.55, y: -1.50, z: 118.6 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.1
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.7, y: -2.2, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.12
    },
    {
        glb: 'uva2.glb',
        position: { x: 0.15, y: -2.10, z: 118.65 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.2
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.1, y: -2.6, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.1
    },
    {
        glb: 'uva2.glb',
        position: { x: -0.9, y: -2.8, z: 118.5 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.22
    },
    {
        glb: 'uva2.glb',
        position: { x: 0.55, y: -2.70, z: 118.6 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.2
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.6, y: -3.22, z: 118.45 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.12
    },
    {
        glb: 'uva2.glb',
        position: { x: 0.55, y: -3.70, z: 118.6 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.2
    },
    {
        glb: 'uva3.glb',
        position: { x: -0.2, y: -3.52, z: 118.45 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 0.12
    }
]