import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

import { renderer } from './index';
import { sceneState } from './state';

export function createScene() {

    const scene = new THREE.Scene();
    scene.position.set(sceneState.position.x, sceneState.position.y, sceneState.position.z);

    return scene;

}

export function animateScene(scene, config, time) {
    new TWEEN.Tween(scene).to(config, time).easing(TWEEN.Easing.Cubic.Out).start();
}

export function onPointerMove(scene, event) {

    let xNuggets = Math.abs((event.clientX / renderer.domElement.clientWidth)) / 300;
    let yNuggets = Math.abs((event.clientY / renderer.domElement.clientHeight)) / 800;

    const nuggets = scene.children.filter(children => children.name === 'nuggets');
    if (nuggets.length) nuggets[0].rotation.set(yNuggets, xNuggets, nuggets[0].rotation.z);

}