"use client";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ANTD
import { CloseOutlined } from '@ant-design/icons';

// THREE
import { group } from '../Three/nuggets';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import Summary from '../Summary/Summary';

// INTERFACES
import { WineCollection } from '../shared/interfaces';

// STYLES
import './Cart.scss';

export default function Cart() {

    const [state, dispatch] = useGlobalState();
    
    const navigate = useNavigate();

    const [totalAmount, setTotalAmount] = useState<number>();

    const checkout = async(): Promise<void> => {
        navigate('/cart/checkout');
    };

    const removeItem = (index: number): void => {
        dispatch({ cart: state.cart.filter((wine: WineCollection, i: number) => i != index) });
    };

    useEffect(() => {
        group.visible = false;
    }, []);


    if (state.cart.length == 0) {
        return (
            <div className="cart-container empty">
                <div className="cart-content">
                    <h1>Your cart is empty</h1>
                </div>
            </div>
        );
    }

    return (
        <div className="cart-container">
            <div className="cart-content">
                <div className="cart-items">
                    {state.cart.map((wine: WineCollection, i: number) => {
                        return (
                            <div className="cart-item" key={i}>
                                <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                                <div className="cart-item-info">
                                    <div>{wine.name}, {wine.year}</div>
                                    <div>{wine.cellar}</div>
                                </div>
                                <div className="cart-item-currency">
                                    <span className="currency">{wine.nfts[0].owner._id == state.account?._id ? "0 EUR" : `${wine.nfts[0].price}  EUR`}</span>
                                </div>
                                <div className="remove-item">
                                    <CloseOutlined onClick={() => removeItem(i)}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Summary/>
        </div>
    );

};