
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// ANTD
import { Modal, Upload, UploadProps, message } from 'antd';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import { CameraOutlined } from '@ant-design/icons';

// SERVICES
import { createCollection } from '../shared/services';

// STYLES
import './AddCollection.scss';

// INTERFACES
import { MintCollection } from '../shared/interfaces';

const { Dragger } = Upload;

export default function AddCollection() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();
    
    const [loader, setLoader] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>();
    const [mintModalState, setMintModalState] = useState<boolean>(false);

    const [form] = Form.useForm();

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${process.env.REACT_APP_API_URL}/collections/upload`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading ...');
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                setFileName(info.file.name);
                form.setFieldValue('image', info.file.name);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const mintCollection = async(): Promise<void> => {

        setLoader(true);
        // setMintModalState(true);

        const wineCollection: MintCollection = {
            name: form.getFieldValue('name'),
            creator: state.account?._id!,
            creatorAddress: state.account?.address!,
            description: form.getFieldValue('description'),
            year: form.getFieldValue('year'),
            size: form.getFieldValue('size'),
            cellar: form.getFieldValue('cellar'),
            origin: {
                city: form.getFieldValue('city'),
                country: form.getFieldValue('country'),
            },
            price: form.getFieldValue('price'),
            image: form.getFieldValue('image')
        };

        try {
            
            await createCollection(wineCollection, state.account?.email!);
            
            // setMintModalState(false);
            setLoader(false);

            // navigate(`/profile/${state.account?._id}`)

        } catch (error) {
            console.log(error);
        }
    };

    const handleMintCancel = (): void =>{
        setMintModalState(false);
    };

    return (
        <>
            <div className="add-collection-container">
                <h1>Wine Minter</h1>
                <Form form={form} layout="vertical" className="add-collection-content">
                    {fileName ? (
                        <div className="add-collection-image">
                            <img src={`${process.env.REACT_APP_BLOB_URL}/${form.getFieldValue('image')}`}/>
                        </div>
                    ): (
                        <div className="add-collection-image">
                            <Form.Item name="image" rules={[{ required: true }]}>
                                <Dragger showUploadList={false} {...props}>
                                    <CameraOutlined /> Upload a Picture
                                </Dragger>
                            </Form.Item>
                        </div>
                    )}
                    <div className="add-collection-fields">
                        <Form.Item label="Name of the wine" name="name" rules={[{ required: true, message: "The wine name is required!" }]}>
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Cellar" name="cellar" rules={[{ required: true, message: "The cellar is required!" }]}>
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Year" name="year" rules={[{ required: true, message: "The year is required!" }]}>
                            <Input size="large" placeholder="2020"/>
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true, message: "The description is required!" }]}>
                            <TextArea size="large" placeholder="We would like to know a little bit about your wine. The story, the taste and the aroma for example."/>
                        </Form.Item>
                        <Form.Item label="Size" name="size" rules={[{ required: true, message: "The size is required!" }]}>
                            <Input placeholder="0.75" size="large"/>
                        </Form.Item>
                        <Form.Item label="City" name="city" rules={[{ required: true, message: "The city is required!" }]}>
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Country" name="country" rules={[{ required: true, message: "The country is required!" }]}>
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item className="add-collection-actions" shouldUpdate>
                            {() => (
                                <Button className="mint-btn" onClick={() => mintCollection()} loading={loader} disabled={state.account == undefined || Object.values(form.getFieldsValue()).filter((value) => value == undefined).length > 0 || !form.isFieldsTouched()}>
                                    Mint Collection
                                </Button>
                            )}
                        </Form.Item>
                    </div>
            </Form>
            </div>
            {/* <Modal title={null} open={mintModalState} onCancel={handleMintCancel} footer={null} mask={true} centered>
                <div className="buy-modal-container">
                    <div className="buy-modal-item">
                        <img src={`${process.env.REACT_APP_BLOB_URL}/${form.getFieldValue('image')}`}/>
                        <div className="portfolio-content-label">
                            <h1>{form.getFieldValue('name')}</h1>
                            <h2>{form.getFieldValue('cellar')}, {form.getFieldValue('year')}</h2>
                        </div>
                    </div>
                    <div>
                        <h2>We are creating your collection,<br/>please be patient</h2>
                    </div>
                </div>
            </Modal> */}
        </>
    );
};