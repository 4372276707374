"use client";
import { useEffect, useState } from 'react';

// THREE
import { group } from '../Three/nuggets';

// ANTD
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import GlbViewer from '../shared/GlbViewer/GlbViewer';
import ImageViewer from '../shared/ImageViewer/ImageViewer';

// SERVICES
import { getNftMetadata } from '../shared/services';

// STYLES
import './Tracer.scss';

// INTERFACES
import { Loading3QuartersOutlined } from '@ant-design/icons';

const glbState = { position: { x: 0, y: -1.6, z: 0 }, rotation: { x: 0, y: 1.6, z: 0 }, scale: { x: 3, y: 3, z: 3 }};

export default function Tracer() {

    const [form] = Form.useForm();
    const [state, dispatch] = useGlobalState();
    
    const [loader, setLoader] = useState<boolean>(false);
    const [wine, setWine] = useState<any>();

    const trace = async(): Promise<void> => {
        try {

            setLoader(true);

            const nft = await getNftMetadata(form.getFieldValue('collection'), form.getFieldValue('tokenId'));

            setWine(nft);
            setLoader(false);

        } catch (error) {
            
        }
    };

    useEffect(() => {
        group.visible = true;
    }, [])

    return (
        <div className="tracer-container">
            {!loader ? (
                !wine ? (
                    <div className="tracer-content">
                        <h1>Trace your Wine</h1>
                        <p>Enter the Wine Collection Address and your Token ID to find the track record of your wine.</p>
                    </div>
                ) : (wine?.glb ? <GlbViewer glbUrl={wine.glb} glbState={glbState}/>: <ImageViewer imageUrl={wine.image}/>)
            ): (
                <div className="tracer-content loader">
                    <Loading3QuartersOutlined/>
                </div>
            )}  
            <div className={"tracer-content " + (loader || wine ? "active" : "")}>
                <Form className="tracer-form" form={form} layout="vertical">
                    <Form.Item className="tracer-item collection-address" label="Collection Address" name="collection" required>
                        <Input size="large"/>
                    </Form.Item>
                    <Form.Item className="tracer-item token-id" label="Token Id" name="tokenId" required>
                        <Input size="large"/>
                    </Form.Item>
                    <Form.Item className="tracer-item" shouldUpdate>
                        {() => (
                            <Button className="trace-btn" type="primary" onClick={() => trace()} loading={loader} disabled={Object.values(form.getFieldsValue()).filter((value) => value == undefined).length > 0 || !form.isFieldsTouched()}>
                                Trace
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </div>
    );

};