import { useEffect, useState } from 'react';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// ANTD
import { GetProp, Upload, UploadProps, message } from 'antd';
import Button from 'antd/es/button';
import { CameraOutlined } from '@ant-design/icons';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';

// SERVICES
import { createExperience } from '../shared/services';

// STYLES
import './AddExperience.scss';

// INTERFACES
import { CreateExperience } from '../shared/interfaces';

const { Dragger } = Upload;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export default function AddExperience() {

    const [state, dispatch] = useGlobalState();
    const [loader, setLoader] = useState<boolean>(false);

    const [form] = Form.useForm();

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${process.env.REACT_APP_API_URL}/experiences/upload`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            form.setFieldValue('image', info.file.name);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const addExperience = async(): Promise<void> => {

        setLoader(true);

        const experience: CreateExperience = {
            name: form.getFieldValue('name'),
            description: form.getFieldValue('description'),
            image: form.getFieldValue('image'),
            year: form.getFieldValue('year'),
            cellar: form.getFieldValue('cellar'),
            email: state.account?.email || form.getFieldValue('email'),
            location: form.getFieldValue('location'),
            city: form.getFieldValue('city'),
            country: form.getFieldValue('country'),
            notes: {
                aroma: form.getFieldValue('aroma'),
                body: form.getFieldValue('body'),
                taste: form.getFieldValue('taste'),
                finish: form.getFieldValue('finish'),
                quality: form.getFieldValue('quality'),
                average: form.getFieldValue('average'),
            }
        };

        try {

            const createdExperience = await createExperience(experience);

            setLoader(false);
            
        } catch (error) {
            
        }
    };

    const setQualityValue = (): void => {
        if (form.getFieldValue('aroma') && form.getFieldValue('body') && form.getFieldValue('taste') && form.getFieldValue('finish') && form.getFieldValue('quality')) {
            const average = ((Number(form.getFieldValue('aroma')) + Number(form.getFieldValue('body')) + Number(form.getFieldValue('taste')) + Number(form.getFieldValue('finish')) + Number(form.getFieldValue('quality'))) / 5).toFixed(1);
            form.setFieldValue('average', average);
        } else {
            form.setFieldValue('average', '');
        }
    }
 
    useEffect(() => {
    }, []);

    return (
        <div className="add-experience-container">
            <div className="add-experience-content">
                <h1>Add Experience</h1>
                <p>The global wine industry is on the brink of transformation, thanks to the rise of blockchain technology. By leveraging this revolutionary digital ledger system, businesses in the sector are able to improve their traceability procedures and authentication processes – helping them provide consumers with a high-quality product.</p>
                <Form form={form} layout="vertical" className="add-experience-form" onChange={() => setQualityValue()}>
                    <div className="add-collection-image">
                        <Form.Item>
                            <Dragger showUploadList={false} {...props}>
                                <CameraOutlined /> Upload a Picture
                            </Dragger>
                        </Form.Item>
                    </div>
                    <div className="add-experience-fields">
                        <Form.Item label="Name" name="name">
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Cellar" name="cellar">
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Year" name="year">
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <TextArea placeholder="Describe in a couple of sentences your experience with the wine" size="large"/>
                        </Form.Item>
                        {!state.account?.email ? (
                            <Form.Item label="Email" name="email">
                                <Input size="large"/>
                            </Form.Item>
                        ): null}
                    </div>
                    <div className="location-fields">
                        <h1>Location</h1>
                        <Form.Item layout='vertical' label="Location" name="aroma">
                            <Input size="large" placeholder="Name of the restaurant, club or bar"/>
                        </Form.Item>
                        <Form.Item layout='vertical' label="City" name="taste">
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item layout='vertical' label="Country" name="body">
                            <Input size="large"/>
                        </Form.Item>
                    </div>
                    <div className="notes-fields">
                        <h1>Wine Notes</h1>
                        <p>Rate your wine experience (1/5)</p>
                        <Form.Item layout='horizontal' label="Aroma" name="aroma">
                            <Input size="large" type='number'/>
                        </Form.Item>
                        <Form.Item layout='horizontal' label="Taste" name="taste">
                            <Input size="large" type='number'/>
                        </Form.Item>
                        <Form.Item layout='horizontal' label="Body" name="body">
                            <Input size="large" type='number'/>
                        </Form.Item>
                        <Form.Item layout='horizontal' label="Finish" name="finish">
                            <Input size="large" type='number'/>
                        </Form.Item>
                        <Form.Item layout='horizontal' label="Quality" name="quality">
                            <Input size="large" type='number'/>
                        </Form.Item>
                        <Form.Item  className="average" layout='horizontal' label="Average" name="average">
                            <Input size="large" type='number'/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item className="add-experience-actions" shouldUpdate>
                            {() => (
                                <Button className="add-experience-btn" onClick={() => addExperience()} loading={loader} disabled={loader}>Add Experience</Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
};