import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import {scene} from './index';

export const group = new THREE.Group();
group.name = 'nuggets';

export function loadNuggets(scene, nuggetsState) {
    for (let i = 0; i < nuggetsState.length; i++) {
        loadNuggetModel(nuggetsState[i]);
    }
    scene.add(group);
}

export async function loadNuggetModel(nugget) {

    const loader = new GLTFLoader();

    if (nugget && nugget.position) {
        loader.load(`/assets/glbs/${nugget.glb}`, (gltf) => {

            let model = gltf.scene;
            model.name = 'nugget';
            
            model.position.set(nugget.position.x, nugget.position.y, nugget.position.z);
            model.scale.set(nugget.scale, nugget.scale, nugget.scale);
            
            group.add(model);

        });
    }
}

export async function loadCollection(
    scenes,
    collectionGlb,
    glbState = { position: { x: 0, y: -3.5, z: 0 }, rotation: { x: 0, y: 1.6, z: 0 }, scale: {x: 5, y: 5, z: 5}}
) {

    const loader = new GLTFLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: "js" });
    dracoLoader.setDecoderPath("/three/examples/jsm/libs/draco/gltf/");

    loader.setDRACOLoader(dracoLoader);

    

    return new Promise((resolve, reject) => {
        loader.load(`/assets/glbs/${collectionGlb}`, (gltf) => {
            
            let model = gltf.scene;
            model.name = 'collection';

            model.position.set(glbState.position.x, glbState.position.y, glbState.position.z);
            model.scale.set(glbState.scale.x, glbState.scale.y, glbState.scale.z);
            model.rotation.set(glbState.rotation.x, glbState.rotation.y, glbState.rotation.z);

            model.castShadow = true;
            model.receiveShadow = true;

            scenes.add(model);
            resolve();
        });
    });

};

// Uvamuestra2


