import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

// THREE
import { init } from './Three';

// COMPONENTS
import Account from './Account/Account';
import AddExperience from './AddExperience/AddExperience';
import About from './About/About';
import Balance from './Balance/Balance';
import Cart from './Cart/Cart';
import CheckOut from './CheckOut/CheckOut';
import Experiences from './Experiences/Experiences';
import Header from './shared/Header/Header';
import Login from './Login/Login';
import Minter from './Minter/Minter';
import PortfolioItem from './Portfolio/PortfolioItem/PortfolioItem';
import Portfolio from './Portfolio/Portfolio';
import Register from './Register/Register';
import SommeliersList from './Sommeliers/Sommeliers';
import Team from './Team/Team';
import Tracer from './Tracer/Tracer';
import WineList from './WineList/WineList';
import Wine from './Wine/Wine';

// STYLES
import './App.scss';

let isInit = false;

function App() {

  useEffect(() => {
    !isInit && init();
    isInit = true;
  }, [])

  return (
    <>
    <Header/>
      <Routes>
        <Route path="/" element={<WineList />} />
        <Route path="/account" element={<Account />} />
        <Route path="/balance" element={<Balance />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart/checkout" element={<CheckOut />} />
        <Route path="/collection" element={<WineList />} />
        <Route path="/collection/:collectionId" element={<Wine />} />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/experiences/add-experience" element={<AddExperience />} />
        <Route path="/sommeliers" element={<SommeliersList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/portfolio/:accountId" element={<Portfolio />} />
        <Route path="/portfolio/:accountId/collection/:collectionId" element={<PortfolioItem />} />
        <Route path="/about" element={<About />} />
        <Route path="/minter" element={<Minter />} />
        <Route path="/minter/:address" element={<Minter />} />
        <Route path="/team" element={<Team />} />
        <Route path="/tracer" element={<Tracer />} />
      </Routes>
    </>
  );
}

export default App;
