export interface WineCollection {
    _id?: string;
    name: string;
    description: string;
    creator: Account;
    year: string;
    size: string;
    cellar: string;
    production?: string;
    origin: {
        city: string;
        country: string;
    };
    units: number;
    buyPrice: number;
    price: number;
    image: string;
    glb: string;
    nfts: Nft[];
    sommelier: Sommelier;
    investors: Investor[];
    views: number;
    address: string;
    active: boolean;
}

export interface BuyNft {
    email: string;
    collectionAddress: string;
    tokenId: number;
}

export interface WineCollectionLog {
    type: string;
    address: string;
    blockHash:  string;
    blockHumber: number;
    data: string;
    index: number;
    removed: boolean;
    topics: string[],
    transactionHash: string;
    transactionIndex: number;
}

export interface WineLogTopic {
    owner: string;
    tokenId: number;
}

export interface MintCollection {
    name: string;
    creator: string;
    creatorAddress: string;
    description: string;
    year: string;
    size: string;
    cellar: string;
    origin: Origin;
    price: number;
    image: string;
};

export interface Origin {
    city: string;
    country: string;
};

export interface Sommelier {
    _id?: string;
    name: string;
    image: string;
    rate: number;
    recommendations: string[];
    origin: {
        city: string;
        country: string;
    }
};

export interface Investor {
    address: string;
    tokenId: string;
};

export interface Account {
    _id: string;
    name: string;
    lastName: string;
    email: string;
    address?: string;
    balance?: number;
    avatar?: string;
    password?: string;
    token?: string;
}

export interface CreateAccount {
    email: string;
    password: string;
}

export interface LoginAccount {
    email: string;
    password: string;
}

export interface Nft {
    _id?: string;
    collectionAddress: string;
    owner: Account;
    tokenId: number;
    buyPrice?: number;
    price?: number;
    status: NftStatus;
};

export enum NftStatus {
    MINTED = "MINTED",
    HOLD = "HOLD",
    SALE = "SALE",
    SOLD = "SOLD",
};

export interface DecodedToken {
    _id: string;
    account: string;
    name: string;
    lastName: string;
    email: string;
    address: string;
    iat: number;
    exp: number;
};

export interface SetNftPrice {
    email: string;
    collectionAddress: string;
    tokenId: number;
    price: number;
};

export interface BuyNft {
    email: string;
    owner: string;
    collectionAddress: string;
    tokenId: number;
}

export interface SubscribePayload {
    name: string;
    email: string;
}

export interface CreateExperience {
    name: string;
    description: string;
    image: string;
    cellar: string;
    year: string;
    email: string;
    location: string;
    city: string;
    country: string;
    notes: {
        aroma: string;
        body: string;
        taste: string;
        finish: string;
        quality: string;
        average: string;
    }
}
export interface Experience {
    _id: string;
    name: string;
    description: string;
    image: string;
    cellar: string;
    year: string;
    email: string;
    notes: {
        aroma: string;
        body: string;
        taste: string;
        finish: string;
        quality: string;
        average: string;
    }
};

export interface Order {
    _id?: string;
    items: any[];
    status: OrderStatus;
};

export enum OrderStatus {
    MINTED = "MINTED",
    HOLD = "HOLD",
    SALE = "SALE",
    SOLD = "SOLD",
};

export type ActibeTab = 'ACCOUNT' | 'DELIVERY' | 'PAYMENT' | 'ORDERS';