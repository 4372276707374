import { useEffect } from "react";

// STATE
import { useGlobalState } from "../GlobalStateProvider/GlobalStateProvider";

// COMPONENTS
import  SommelierItem from './SommelierItem/Sommeliertem';

// INTERFACES
import { Sommelier } from "../shared/interfaces";

// STYLES
import "./Sommeliers.scss";

export const sommeliersList: Sommelier[] = [
    {
        _id: '',
        name: 'Pingus',
        image: 'julio.jpg',
        rate: 4,
        recommendations: [],
        origin: {
            city: 'Madrid',
            country: 'Spain'
        }
    },
    {
        _id: '',
        name: 'Pingus',
        image: 'julio.jpg',
        rate: 4,
        recommendations: [],
        origin: {
            city: 'Madrid',
            country: 'Spain'
        }
    },
    {
        _id: '',
        name: 'Pingus',
        image: 'julio.jpg',
        rate: 4,
        recommendations: [],
        origin: {
            city: 'Madrid',
            country: 'Spain'
        }
    },
];

export default function SommeliersList() {

    const [state, dispatch] = useGlobalState();

    useEffect(() => {
    }, []);

    return (
        <div className="sommeliers-list-container">
            <div className="sommeliers-list-content">
                {sommeliersList.map((sommelier: Sommelier, i: number) => {
                    return <SommelierItem sommelier={sommelier} key={i}/>
                })}
            </div>
        </div>
    );
};