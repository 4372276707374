"use client";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// THREE
import { group } from '../Three/nuggets';

// ANTD
import Button from 'antd/es/button';

// COMPONENTS
import ExperienceItem from './ExperienceItem/ExperienceItem';

// SERVICES
import { getExperiences } from '../shared/services';

// INTERFACES
import { Experience } from '../shared/interfaces';

// STYLES
import './Experiences.scss';

export default function Experiences() {

    const [experiences, setExperiences] = useState<Experience[]>([]);
    const navigate = useNavigate();
    
    const loadExperiences = async (): Promise<void> => {
        try {

            const experiences = await getExperiences();

            setExperiences(experiences);

        } catch (error) {
            
        }
    };  

    useEffect(()=> {
        group.visible = true;
        loadExperiences();
    }, []);
    
    return (
        <div className="experiences-container">
            <div className="experiences-content">
                <h1>Experiences</h1>
                <p>The global wine industry is on the brink of transformation, thanks to the rise of blockchain technology. By leveraging this revolutionary digital ledger system, businesses in the sector are able to improve their traceability procedures and authentication processes – helping them provide consumers with a high-quality product.</p>
                <div>
                    <Button className="experience-btn" onClick={() => navigate('/experiences/add-experience')}>Add Experience</Button>
                </div>
            </div>
            <div className="experiences-items-content">
                {experiences.length > 0 ? (
                    experiences.map((experience: Experience, i: number) => {
                        return <ExperienceItem experience={experience} index={i} key={i}/>
                    })
                ) : null}
            </div>
        </div>
        
    );

};