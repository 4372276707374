import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

// STATE
import { sceneState, nuggetsState } from './state';

// DEPENDENCIES
import { createScene, onPointerMove } from './scene';
import { loadNuggets } from './nuggets';

export let camera;
export let scene, controls;
export let renderer, clock, raycaster, mouse, stats;
export let hemiLight, hemiLight2, spotLight;


const root = document.getElementById('root');

export function init() {

    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 120;

    clock = new THREE.Clock(true);

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2(0, 0);

    initRenderer();
    initScene();

    window.addEventListener('resize', onWindowResize);

    loadNuggets(scene, nuggetsState);

    animate();

}

export function animate() {

    requestAnimationFrame(animate);

    render();

    TWEEN.update();

}

function initScene() {

    scene = createScene(sceneState.position, sceneState.rotation);

    createLight(scene);
    animateScene(scene);

};

function animateScene(scene) {
    window.addEventListener('pointermove', (event) => onPointerMove(scene, event));
    window.addEventListener('scroll', (event) => onWheel(scene, event));
};

export function createLight(scene) {

    const hlight = new THREE.AmbientLight( 0x404040, 1.2 );
    scene.add( hlight );

    const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
    directionalLight.castShadow = true;
    directionalLight.position.set( 0, 20, 20 );
    scene.add( directionalLight );

};

function initRenderer() {
    
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, autoClear: true });
    renderer.shadowMap.enabled = true;
    renderer.sortObjects = true;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);


    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    
    document.body.appendChild(renderer.domElement);
};

export function initCollectionRenderer(canvas) {
    
    const renderer = new THREE.WebGLRenderer({ canvas: canvas.current, alpha: true, antialias: true, autoClear: true });

    renderer.shadowMap.enabled = true;

    renderer.sortObjects = true;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvas.current.clientWidth, canvas.current.clientHeight);

    renderer.toneMapping = 1;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    return renderer;
    
};

function render() {
    renderer.render(scene, camera);
};

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

};

function onWheel(scene, event) {

    const nuggets = scene.children.filter(children => children.name === 'nuggets');
    if (nuggets.length) {
        nuggets[0].position.y = getNuggetsPosition();
        nuggets[0].children.forEach(nugget => {
            nugget.rotation.x = getNuggetsRotation(-0.02);
        });
    }

}

function getNuggetsRotation(param) {

    const scrollPosition = document.documentElement.scrollTop;
    const percent = scrollPosition * 100 / (root.clientHeight - window.innerHeight);
    return (param * percent);
}
function getNuggetsPosition() {

    const scrollPosition = document.documentElement.scrollTop;
    const percent = scrollPosition * 100 / (root.clientHeight - window.innerHeight);
    return percent / 100;

}