import { MintCollection, CreateAccount, WineCollection, LoginAccount, Account, WineCollectionLog, BuyNft, DecodedToken, Nft, SetNftPrice, SubscribePayload, CreateExperience, Experience, Order } from "./interfaces";

export async function getCollections(): Promise<WineCollection[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function createCollection(createCollection: MintCollection, email: string): Promise<WineCollection> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/create`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token') as string}`
        },
        body: JSON.stringify({ createCollection, email })
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function mintCollection(collectionAddress: string, owner: string, units: number, email: string): Promise<void> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/mint/${collectionAddress}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token') as string}`
        },
        body: JSON.stringify({ owner, units, email })
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function createAccount(createAccount: CreateAccount): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/create`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ createAccount })
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function loginAccount(loginAccount: LoginAccount): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ loginAccount })
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function loginGoogle(codeResponse: string): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/login-google`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ googleCode: codeResponse })
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function validate(token: string): Promise<DecodedToken> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/validate/${token}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getWineCollection(wineCollection: string): Promise<WineCollection> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/${wineCollection}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getWineCollectionByAddress(collectionAddress: string): Promise<WineCollection> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/address/${collectionAddress}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getNftsByOwner(owner: string): Promise<Nft[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/nfts/owner/${owner}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getCollectionsByOwner(owner: string): Promise<WineCollection[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/owner/${owner}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getWineCollectionLogs(address: string): Promise<WineCollectionLog[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/logs/${address}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getNftMetadata(collectionAddress: string, tokenId: number): Promise<WineCollectionLog> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collections/${collectionAddress}/nft/${tokenId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function buyNft(buyNft: BuyNft): Promise<any> {
    return await fetch(`${process.env.REACT_APP_API_URL}/nfts/buy`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(buyNft)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function setItemPrice(setNftPrice: SetNftPrice): Promise<void> {
    return await fetch(`${process.env.REACT_APP_API_URL}/nfts/price`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(setNftPrice)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function holdItem(setNftPrice: SetNftPrice): Promise<void> {
    return await fetch(`${process.env.REACT_APP_API_URL}/nfts/hold`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(setNftPrice)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function buyItem(itemToBuy: BuyNft): Promise<void> {
    return await fetch(`${process.env.REACT_APP_API_URL}/nfts/buy`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(itemToBuy)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function subscribeEmail(subscribe: SubscribePayload): Promise<boolean> {
    return await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(subscribe)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function getOwnerByAddress(owner: string): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/address/${owner}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getAddressBalance(address: string): Promise<number> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/balance/${address}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function setAccount(account: Account): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/save`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token') as string}`
        },
        body: JSON.stringify(account)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function createExperience(createExperience: CreateExperience): Promise<Account> {
    return await fetch(`${process.env.REACT_APP_API_URL}/experiences/create`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token') as string}`
        },
        body: JSON.stringify(createExperience)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function getExperiences(): Promise<Experience[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/experiences`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}

export async function getCollectionTrace(collection: string): Promise<any> {
    return await fetch(`${process.env.REACT_APP_API_URL}/collection/${collection}/trace`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getStripeConfig(): Promise<any> {
    return await fetch(`${process.env.REACT_APP_API_URL}/stripe/config`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.text();
    }).catch((err) => console.log(err));
};

export async function createPaymentIntent(): Promise<any> {
    return await fetch(`${process.env.REACT_APP_API_URL}/stripe/payment-intent`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
};

export async function getAccountOrders(account: string): Promise<Order[]> {
    return await fetch(`${process.env.REACT_APP_API_URL}/orders/${account}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}