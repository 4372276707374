"use client";
import { useEffect, useState } from 'react';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import TraceItem from './TraceItem/TraceItem';

// SERVICES
import { getCollectionTrace, getNftMetadata } from '../shared/services';

// STYLES
import './Trace.scss';

export default function Trace({ collection }: { collection?: string }) {

    const [state, dispatch] = useGlobalState();

    const [collectionsTrace, setCollectionTrace] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);


    const trace = async(collection: string, tokenId: number): Promise<void> => {
        try {

            setLoader(true);

            const nft = await getNftMetadata(collection, tokenId);
            console.log(nft);
            
            setLoader(false);

        } catch (error) {
            
        }
    };

    useEffect(() => {
        state.trace && trace(state.trace.collection, state.trace.tokenId);
    }, [state.trace]);

    return (
        <div className="trace-container">
            <div className="trace-content">
                {collectionsTrace?.length ? (
                    collectionsTrace?.map((trace: any) => {
                        return <TraceItem trace={trace}/>
                    })
                ) : (
                    <div>
                        <h2>No trace records found.</h2>
                    </div>
                )}
            </div>
        </div>
    );
};