"use client";
import { useEffect, useState } from 'react';

// STYLES
import './TraceItem.scss';

export default function TraceItem({ trace } : { trace: any }) {
    return (
        <div className="trace-item-container">
            <div className="trace-item-content">
                <h1>No track record found.</h1>
            </div>
        </div>
    );
};