"use client";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import ImageViewer from '../../shared/ImageViewer/ImageViewer';

// SERVICES
import { getWineCollectionByAddress } from '../../shared/services';

// INTERFACES
import { Nft, WineCollection } from '../../shared/interfaces';

// STYLES
import './PortfolioCollection.scss';

export default function PortfolioCollection({ account, wineCollection }: { account: string; wineCollection: WineCollection }) {

    const navigate = useNavigate();

    const [collection, setCollection] = useState<WineCollection>();

    const getCollectionByAddress = async(collectionAddress: string): Promise<void> => {
        try {
            
            const collectionByAddress = await getWineCollectionByAddress(collectionAddress);
            setCollection(collectionByAddress);
            
        } catch (error) {
            
        }
    };

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    useEffect(() => {
        // collectionAddress && getCollectionByAddress(collectionAddress);
    }, [])

    return (
        <div className="portfolio-collection-container" onClick={() => navigateTo(`/portfolio/${account}/collection/${wineCollection?._id}`)}>
            <div className="portfolio-collection-content">
                <ImageViewer imageUrl={`${process.env.REACT_APP_BLOB_URL}/${wineCollection?.image}`}/>
                <div className="portfolio-content-label">
                    <h1>{wineCollection?.name}, {wineCollection?.year}</h1>
                    <h2>{wineCollection?.cellar}</h2>
                </div>
            </div>
        </div>
    );

};