"use client";
import { useEffect, useState } from 'react';

// STRIPE
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// ANTD
import Button from 'antd/es/button';
import { Form } from 'antd';
import Input from 'antd/es/input/Input';

// THREE
import { group } from '../Three/nuggets';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import Payment from '../Payment/Payment';
import Summary from '../Summary/Summary';

// SERVICES
import { createPaymentIntent, getStripeConfig } from '../shared/services';

// STYLES
import './CheckOut.scss';

// CONSTANTS
import { appearance } from './CheckOut.constants';

export default function CheckOut() {

    const [state, dispatch] = useGlobalState();

    const [stripePromise, setStripePromise] = useState<any>();
    const [clientSecret, setClientSecret] = useState<string>();
    const [loader, setLoader] = useState<boolean>(true);

    const [form] = Form.useForm();

    const setStripeConfig = async(): Promise<void> => {
        try {

            const stripeConfig = await getStripeConfig();

            setStripePromise(loadStripe(stripeConfig));

            await paymentIntent();

        } catch (error) {
            
        }
    };

    const paymentIntent = async(): Promise<void> => {
        try {

            setLoader(true);

            const paymentIntent = await createPaymentIntent();

            setClientSecret(paymentIntent.clientSecret);

            setLoader(false);

        } catch (error) {
            
        }
    };

    useEffect(() => {
        setStripeConfig();
    }, []);

    useEffect(() => {
        group.visible = false;
    }, []);

    if (loader) {
        return (
            <div className="payment-container">
                <div className="payment-content">
                    Loading ...
                </div>
            </div>
        );
    }

    return (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <div className="checkout-container">
                <div className="checkout-content">
                    <h1>Contact Details</h1>
                    <Form  form={form} layout="vertical">
                        <Form.Item label="Name" name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Lastname" name="lastName">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Email" name="email">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Phone" name="phone">
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
                <div className="checkout-content">
                    <h1>Delivery Address</h1>
                    <Form  form={form} layout="vertical">
                        <Form.Item label="Address" name="address">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Postal Code" name="postal_code">
                            <Input />
                        </Form.Item>
                        <Form.Item label="City" name="city">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Country" name="address">
                            <Input  />
                        </Form.Item>
                    </Form>
                </div>
                <div className="checkout-content">
                    <h1>Payment</h1>
                    <Payment/>
                </div>
                <div className="checkout-content">
                    <Summary payment={true}/>
                    <MakePayment/>
                </div>
            </div>
        </Elements>
    );

};

export function MakePayment() {

    const stripe = useStripe();
    const elements = useElements();

    const pay = async(): Promise<void> => {
        const payment = await stripe?.confirmPayment({
            elements: elements!,
            confirmParams: {
                return_url: `${window.origin}/thank-you`
            }
        });

        if (payment?.error) console.log('error!');
    };

    return (
        <div className="cart-checkout">
            <Button size="large" className="cart-checkout-btn" type="primary" onClick={() => pay()}>
                Make Payment
            </Button>
        </div>
    );
}
