// INTERFACES
import { Experience } from '../../shared/interfaces';

// STYLES
import './ExperienceItem.scss';

export default function ExperienceItem({ experience, index } : { experience: Experience; index: number }) {
    return (
        <div className="experience-item-container">
            <div className="experience-item-content">
                <div className="experience-image">
                    <img src={`${process.env.REACT_APP_BLOB_URL}/${experience.image}`}/>
                </div>
                <div className="experience-desc">
                    <div className="experience-header">
                        <h1>{experience.name}, {experience.cellar} {experience.year}</h1>
                        <p>{experience.description}</p>
                    </div>
                    <div className="experience-notes">
                        <div className="experience-note">
                            Aroma <span>{experience.notes.aroma}</span>
                        </div>
                        <div className="experience-note">
                            Body <span>{experience.notes.body}</span>
                        </div>
                        <div className="experience-note">
                            Taste <span>{experience.notes.taste}</span>
                        </div>
                        <div className="experience-note">
                            Finish <span>{experience.notes.finish}</span>
                        </div>
                        <div className="experience-note">
                            Quality <span>{experience.notes.quality}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};