import { Loading3QuartersOutlined } from "@ant-design/icons";
import { RefObject, useEffect, useRef, useState } from "react";

// THREEJS
import * as THREE from 'three';
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { loadCollection } from "../../Three/nuggets";
import { createLight, initCollectionRenderer } from "../../Three";

// STYLES
import './GlbViewer.scss';

let isInit = false;

export interface Coord {
    x: number;
    y: number;
    z: number;
};

export interface GlbState {
    scale: Coord;
    position: Coord;
    rotation: Coord;
};

export default function GlbViewer({ glbUrl, glbState }: { glbUrl: string | undefined, glbState?: GlbState }) {

    const [loader, setLoader] = useState<boolean>(true);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const setUpCollectionScene = async (glb: string, canvasRef: RefObject<HTMLCanvasElement>): Promise<void> => {
        
        isInit = true;
        return new Promise(async (resolve, reject) => {
                
            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(75, canvasRef.current!.clientWidth / canvasRef.current!.clientHeight, 0.1, 1000);
            const renderer = initCollectionRenderer(canvasRef);

            const controls = new OrbitControls(camera, renderer.domElement);
            controls.minDistance = 2;
            controls.maxDistance = 8;
            controls.maxPolarAngle = Math.PI;
            controls.target.set(0, 0, 0);
            controls.enableDamping = true;
            controls.dampingFactor = 0.05;
            controls.maxAzimuthAngle = 1;
            controls.minAzimuthAngle = -1;

            camera.updateProjectionMatrix();
            
            createLight(scene);
    
            await loadCollection(scene, glb, glbState);
    
            const box = new THREE.Box3().setFromObject(scene);
            const size = box.getSize(new THREE.Vector3()); 
            const center = box.getCenter(new THREE.Vector3()); 
    
            const maxDim = Math.max(size.x, size.y, size.z);
            const fov = camera.fov * (Math.PI / 180); 
            let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2)); 
    
            cameraZ *= 9;
    
            camera.position.z = center.z + cameraZ;
            camera.position.x = center.x;
            camera.position.y = center.y + 0.5;
    
            camera.lookAt(center);
    
            const animate = () => {
                requestAnimationFrame(animate);
                controls.update();
                renderer.render(scene, camera);
            };
    
            animate();

            isInit = false;

            setLoader(false);
            resolve();
    
        });
    };

    useEffect(() => {
        !isInit && glbUrl && canvasRef && setUpCollectionScene(glbUrl, canvasRef);
    }, [glbUrl, canvasRef]);

    if (loader) {
        <div className="glb-viewer-container">
            <div className="glb-viewer-content">
                <Loading3QuartersOutlined/>
            </div>
        </div>
    }

    return (
        <div className="glb-viewer-container">
            <canvas ref={canvasRef}></canvas>
        </div>
    );
    
};