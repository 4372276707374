"use client";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// THREE
import { group } from '../Three/nuggets';
// ANTD
import Button from 'antd/es/button';
import { LoadingOutlined, QrcodeOutlined } from '@ant-design/icons';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import GlbViewer from '../shared/GlbViewer/GlbViewer';
import Modal from 'antd/es/modal';
import Trace from '../Trace/Trace';
import WineLog from './WineLog/WineLog';

// SERVICES
import { buyNft, getWineCollection } from '../shared/services';

// INTERFACES
import { BuyNft, Nft, WineCollection } from '../shared/interfaces';

// STYLES
import './Wine.scss';

export default function Wine() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const [wine, setWine] = useState<WineCollection>();
    const [loading, setLoading] = useState<boolean>(true);

    const [buyLoader, setBuyLoader] = useState<boolean>(false);
    const [buyModalState, setBuyModalState] = useState<boolean>(false);

    const [activeNft, setActiveNft] = useState<Nft>();

    const location = useLocation();

    const buy = async(): Promise<void> => {
        try {

            setBuyLoader(true);

            const itemToBuy: BuyNft = {
                email: state.account?.email!,
                owner: activeNft?.owner!.address!,
                collectionAddress: wine?.address!,
                tokenId: activeNft?.tokenId!
            };

            await buyNft(itemToBuy);

            setBuyModalState(false);
            setBuyLoader(false);

        } catch (error) {
            
        }
    };

    const setWineCollection = async (wineCollectionId: string): Promise<void> => {
        try {

            const wineCollection = await getWineCollection(wineCollectionId);
            // wineCollection?.glb && await loadCollection(wineCollection?.glb);

            setWine(wineCollection);
            setLoading(false);

        } catch (error) {
            
        }
    };

    const handleBuyCancel = (): void =>{
        setBuyModalState(false);
    };

    useEffect(() => {
        group.visible = false;
        location.pathname && setWineCollection(location.pathname.substring(location.pathname.indexOf('/', 1) + 1, location.pathname.length));
    }, []);

    if (loading) {
        <div className="wine-container">
            <div className="wine-content">
                <LoadingOutlined />
            </div>
        </div>
    }

    if (!loading && wine) {
        return (
            <>
                <div className="wine-container">
                    <div className="wine-content">
                        {wine?.glb ? (
                            <GlbViewer glbUrl={wine.glb}/>
                        ) : (
                            <div className="wine-image">
                                <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                            </div>
                        )}
                        <div className="wine-info">
                            <div className="wine-details-sheet">
                                <h2>{wine.cellar}</h2>
                                <h1>{wine.name}</h1>    
                                <div>{wine.origin.city}, {wine.origin.country}</div>
                                <div className="description">{wine.description}</div>
                                <div className="wine-details">
                                    <div className="wine-detail">Size: {wine.size}</div>
                                    <div className="wine-detail">Year: {wine.year}</div>
                                    <div className="wine-detail">Units: {wine.nfts.length}</div>
                                    <div className="wine-detail">
                                        <span className="address"><QrcodeOutlined /> {`${wine.address.substring(0, 6)}...${wine.address.substring(wine.address.length - 4, wine.address.length)}`}</span>
                                    </div>
                                </div>
                                <div className="social-container">
                                    <div className="social-avatar">
                                        {wine.creator.avatar ? (
                                            <img src=""/>
                                        ): (
                                            <div className="social-first-letters">{wine.creator.name.substring(0, 1)}</div>
                                        )}
                                    </div>
                                    <div className="social-content">
                                        <span>Created by:</span>
                                        <h5 onClick={() => navigate(`/portfolio/${wine.creator._id}`)}>{wine.creator.name} {wine.creator.lastName}</h5>
                                    </div>
                                </div>
                                {/*
                                <div className="social-container">
                                    <Sommelier sommelier={wine.sommelier}/>
                                    <Investors investors={wine.investors}/>
                                </div>
                                */}
                            </div>
                            <div>
                                <h1>Ownership</h1>
                                <div className="positions-header">
                                    <div className="position-header-item position-qr">
                                        Id
                                    </div>
                                    <div className="position-header-item name-item">
                                        Owner
                                    </div>
                                    <div className="position-header-item">
                                        Status
                                    </div>
                                    <div className="position-header-item">
                                        Price
                                    </div>
                                    <div className="position-header-item actions">&nbsp;</div>
                                </div>
                                {wine.nfts?.map((nft: Nft, i: number) => {
                                    return <WineLog wine={wine} nft={nft} key={i}/>
                                })}
                            </div>
                            {/*
                                <div className="wine-item-actions">
                                    <Button className="invest-btn" size="large" onClick={() => buy()}>Buy</Button>
                                    <Button className="invest-btn" size="large" onClick={() => invest()}>Invest</Button>
                                    <Button className="invest-btn" size="large" onClick={() => transfer()}>Transfer</Button>
                                </div>
                            */}
                        </div>
                        <div className="trace-info">
                            <Trace collection={location.pathname.substring(location.pathname.indexOf('/', 1) + 1, location.pathname.length)}/>
                        </div>
                    </div>
                </div>
                <Modal title={null} open={buyModalState} onCancel={handleBuyCancel} footer={null} mask={true} centered>
                    <div className="buy-modal-container">
                        <div className="buy-modal-item">
                            <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                            <div className="portfolio-content-label">
                                <h1>{wine?.name}</h1>
                                <h2>{wine?.cellar}, {wine.year}</h2>
                            </div>
                        </div>
                        <div className="buy-modal-info">
                            <h1>Purchase</h1>
                            <p>As soon as you adquire the bottle, you will be able to order it, trade it or hold it as an investment.</p>
                            <p>Price: <b>{activeNft?.price} EUR</b></p>
                            <Button className="buy-btn" onClick={() => buy()} loading={buyLoader} disabled={buyLoader}>Purchase</Button>               
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

    return(
        <div className="wine-container">
            <div className="wine-content">
                <div className="wine-info">
                    <h1>We are finding your wine ...</h1>
                </div>
            </div>
        </div>
    )
};