"use client";
import { useEffect } from 'react';

// THREE
import { group } from '../Three/nuggets';

// ANTD
import Button from 'antd/es/button';
import { useNavigate } from 'react-router-dom';

// GOOGLE SIGN IN
import { useGoogleLogin } from '@react-oauth/google';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import AddCollection from '../AddCollection/AddCollection';

// STYLES
import './Minter.scss';

export default function Minter() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const startMinting = (): void => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          });
    };

    const registerGoogle = useGoogleLogin({
        onSuccess: codeResponse => console.log(codeResponse),
        flow: 'auth-code',
    });
    
    useEffect(() => {
        group.visible = true;
    }, []);

    return (
        <div className="minter-container">
            <div className="minter-content">
                <h1>Create your<br/>NFT Wine Collection</h1>
                <p>The global wine industry is on the brink of transformation, thanks to the rise of blockchain technology.<br/>Businesses in the sector are able to improve their traceability procedures and authentication processes – helping them provide consumers with a high-quality product.</p>
                {!state.account ? (
                    <>
                        <p>Create your account to start creating your Wine NFTs and become a member of the community.</p>
                        <Button className="ant-btn" onClick={() => navigate('/register?r=minter')}>
                            Connect
                        </Button>
                        <span className="or-content">or</span>
                        <Button className="login-btn" type="primary" onClick={() => registerGoogle()}>
                            Register with Google
                        </Button>
                    </>
                ): (
                    <Button className="ant-btn" onClick={() => startMinting()}>
                        Mint Collection
                    </Button>
                )}
            </div>
            {state.account ? (
                <div className="minter-content">  
                    <AddCollection/>
                </div>
            ): null}
        </div>
    );

};