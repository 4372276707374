import { useNavigate } from 'react-router-dom';

// COMPONENTS
import ImageViewer from '../../shared/ImageViewer/ImageViewer';

// INTERFACES
import { WineCollection } from '../../shared/interfaces';

// STYLES
import './WineItem.scss';

export default function WineItem({ wine }: { wine: WineCollection }) {

    const navigate = useNavigate();

    const navigateTo = (route: string): void => {
        navigate(route);
    };
     
    return (
        <div className="collection-container" onClick={() => navigateTo(`/collection/${wine._id}`)}>
            <div className="collection-content">
                <ImageViewer imageUrl={`${process.env.REACT_APP_BLOB_URL}/${wine?.image}`}/>
                <div className="collection-content-label">
                    <h1>{wine?.name}, {wine?.year}</h1>
                    <h2>{wine?.cellar}</h2>
                </div>
            </div>
        </div>
    );
};