"use client";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ANTD
import Button from 'antd/es/button';
import { CloseOutlined, QrcodeOutlined } from '@ant-design/icons';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// INTERFACES
import { WineCollection } from '../shared/interfaces';

// STYLES
import './Summary.scss';

export default function Summary({ payment }: { payment?: boolean }) {

    const [state, dispatch] = useGlobalState();
    const [totalAmount, setTotalAmount] = useState<number>();
    const navigate = useNavigate();

    const setTotal = () => {
        let total = 0;
        state.cart.forEach((wine: WineCollection) => {
            total += wine.nfts[0].price!;
        });
        setTotalAmount(total);
    };

    const checkout = async(): Promise<void> => {
        navigate('/cart/checkout');
    };

    const removeItem = (index: number): void => {
        dispatch({ cart: state.cart.filter((wine: WineCollection, i: number) => i != index) });
    };

    useEffect(() => {
        setTotal();
    }, [state.cart]);
    // {state.cart.length > 0 ? (`(${state.cart.length})`) : null} 
    return (
        <div className="summary-content">
            <h1>Order Summary</h1>
            <div className="cart-items">
                {state.cart.map((wine: WineCollection, i: number) => {
                    return (
                        <div className="cart-item" key={i}>
                            <div className="cart-item-id">
                                <QrcodeOutlined/>
                            </div>
                            <div className="cart-item-info">
                                <div>{wine.name}, {wine.year}</div>
                                <div>{wine.cellar}</div>
                            </div>
                            <div className="cart-item-currency">
                                <span className="currency">{wine.nfts[0].owner._id == state.account?._id ? "0 EUR" : `${wine.nfts[0].price}  EUR`}</span>
                            </div>
                            <div className="cart-item-remove">
                                <CloseOutlined onClick={() => removeItem(i)}/>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="total-amount">
                Total: <span className="amount">{totalAmount} Eur</span>
            </div>
            {!payment && (
                <div className="cart-checkout">
                    <Button size="large" className="account-btn" type="primary" onClick={() => checkout()}>
                        Checkout
                    </Button>
                </div>
            )}
        </div>
    )
}