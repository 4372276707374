"use client";
import { useEffect } from 'react';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// STYLES
import './Balance.scss';

export default function Balance() {

    const [state, dispatch] = useGlobalState();

    useEffect(() => {
    }, []);

    return (
        <div className="balance-container">
            <div className="balance-content">
                
            </div>
        </div>
    );

};