"use client";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ANTD
import { MenuOutlined, ShoppingFilled } from '@ant-design/icons';

// STATE
import { useGlobalState } from '../../GlobalStateProvider/GlobalStateProvider';

// STYLES
import './Header.scss';

export default function Header() {

    const [headerState, setHeaderState] = useState<boolean>(false);

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const navigateTo = (route: string): void => {
        window.scrollTo(0, 0);
        navigate(route);
        dispatch({ menuState: false });
    };

    const redirect = (route: string): void => {
        window.open(route, '_blank');
    };

    const logout = (): void => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    return (
        <>
            <div className={"header-container " + (headerState ? "active": "")}>
                <div className="header-content">
                    <div className="header-actions left">
                        <ul>
                            <li onClick={() => navigate('/collection')}>Collection</li>
                            {state.account ? (
                                <li onClick={() => navigateTo(`/portfolio/${state.account?._id}`)}>Portfolio</li>
                            ): null}
                            <li onClick={() => navigate('/experiences')}>Experiences</li>
                            <li onClick={() => navigate('/tracer')}>Tracer</li>
                        </ul>
                    </div>
                    <div className="header-main">
                        <MenuOutlined onClick={() => dispatch({ menuState: !state.menuState })}/>
                        <div className="header-title" onClick={() => navigate('/')}>
                            <h1>Les Sommeliers</h1>
                            <h5>Private Collection</h5>
                        </div>
                        <div className="header-cart">
                            {state.cart.length > 0 && <ShoppingFilled  onClick={() => navigate('/cart')}/>}
                        </div>
                    </div>
                    <div className="header-actions right">
                        <ul>
                            <li className="cart" onClick={() => navigate('/cart')}>
                                <ShoppingFilled /> {state.cart.length}
                            </li>
                            <li onClick={() => navigate('/team')}>Team</li>
                            <li onClick={() => navigate('/about')}>About us</li>
                            {state.account ? (
                                <li onClick={() => navigate('/account')}>Account</li>
                            ) : (
                                <li onClick={() => navigate('/login')}>Connect</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"header-container-responsive " + (state.menuState ? "active" : "")}>
                <div className="header-content-responsive">
                    <ul>
                        <li onClick={() => navigateTo('/collection')}>Collections</li>
                        {state.account && <li onClick={() => navigateTo(`/portfolio/${state.account?._id}`)}>Portfolio</li>}
                        <li onClick={() => navigateTo('/experiences')}>Experiences</li>
                        <li onClick={() => navigateTo('/cart')}>Cart ({state.cart.length})</li>
                        <li onClick={() => navigateTo('/about')}>About us</li>
                        {state.account ? (
                            <>
                                <li onClick={() => navigateTo('/account')}>Account</li>
                                <li onClick={() => logout()}>Log Out</li>
                            </>
                        ) : (
                            <li onClick={() => navigateTo('/login')}>Connect</li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};