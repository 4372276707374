import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// STATE
import { useGlobalState } from './GlobalStateProvider/GlobalStateProvider';

// SERVICES
import { getAddressBalance, validate } from './shared/services';

function LesSommeliers({ children }: any) {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const checkAccount = async (): Promise<void> => {
        try {
            
            const token = localStorage.getItem('token') as string;
            
            if (token) {
                
                const decodedToken = await validate(token);

                if (!decodedToken || !decodedToken.account) {
                    localStorage.removeItem('token');
                    navigate('/login');
                    return;
                }

                const balance = await getAccountBalance(decodedToken.address);

                dispatch({
                    account: {
                        _id: decodedToken.account,
                        name: decodedToken.name,
                        lastName: decodedToken.lastName,
                        email: decodedToken.email,
                        address: decodedToken.address,
                        balance
                    }
                });

                return;

            }

            // navigate('/collection');

        } catch (error) {
            
        }
    };

    const getAccountBalance = async (address: string): Promise<number | void> => {
        try {
            return await getAddressBalance(address); 
        } catch (error) {
            
        }
    };

    useEffect(() => {
        checkAccount();
    }, [])

    return(children);
    
}

export default LesSommeliers;