import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// STATE
import GlobalStateProvider from './GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import App from './App';

// STYLES
import './index.scss';
import LesSommeliers from './LesSommeliers';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
        <GoogleOAuthProvider clientId="741584753336-4k6pd7e6eolc29g0v96dso9o78148577.apps.googleusercontent.com">
          <LesSommeliers>
            <App/>
          </LesSommeliers>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
