export const appearance = {
    rules: {    
        '.Label': {
            fontSize: '14px',
            fontFamily: 'Georgia, serif',
            marginBottom: '14px'
        },
        '.Input': {
            border: '1px solid black',
            borderRadius: '0px',
            backgroundColor: 'transparent',
            padding: '8px',
            marginBottom: '22px'
        }
    }
};