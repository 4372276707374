import { useEffect } from 'react';

// STATE
import { useGlobalState } from '../../GlobalStateProvider/GlobalStateProvider';

// INTERFACES
import { Sommelier } from '../../shared/interfaces';

// STYLES
import './SommelierItem.scss';

export default function SommelierItem({ sommelier }: { sommelier: Sommelier }) {

    const [state, dispatch] = useGlobalState();


     
    useEffect(() => {
    }, []);

    return (
        <div className="sommelier-item-container">
            <div className="sommelier-item-content">
                {/* <div className="sommelier-item-image">
                    <img src={`/assets/img/${sommelier.image}`}/>
                </div> */}
                <div className="sommelier-item-info">
                    <h1>{sommelier.name}</h1> 
                    <h5>{sommelier.origin.city}, {sommelier.origin.country}</h5>    
                </div>
            </div>
        </div>
    );
};