"use client";
import { useEffect } from 'react';

// THREE
import { group } from '../Three/nuggets';

// STYLES
import './Team.scss';

export default function About() {
    
    useEffect(() => {
        group.visible = true;
    }, [])

    return (
        <div className="team-container">
            <div className="team-content">
                <h1>Team</h1>
            </div>
        </div>
    );

};