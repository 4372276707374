import { useEffect } from 'react';

// THREE
import { group } from '../Three/nuggets';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import WineItem from '../WineList/WineItem/WineItem';

// SERVICES
import { getCollections } from '../shared/services';

// INTERFACES
import { WineCollection } from '../shared/interfaces';

// STYLES
import './WineList.scss';

export default function WineList() {

    const [state, dispatch] = useGlobalState();
    
    const setWineCollection = async (): Promise<void> => {
        try {

            const wineCollection = await getCollections() || [];
            
            dispatch({ wineCollection });

        } catch (error) {
            
        }
    };

    useEffect(() => {
        group.visible = true;
        setWineCollection();
    }, []);

    return (
        <div className={"wine-list-container " + (state.wine ? "active" : "")}>
            <div className="wine-list-content">
                {state.wineCollection.map((wine: WineCollection, i: number) => {
                    return <WineItem wine={wine} key={i}/>
                })}
            </div>
        </div>
    );
};