"use client";
import { useEffect } from 'react';

// THREE
import { group } from '../Three/nuggets';

// STYLES
import './About.scss';

export default function About() {
    
    useEffect(() => {
        group.visible = true;
    }, [])

    return (
        <div className="about-container">
            <div className="about-content">
                <h1>About us</h1>
                <p>Les Sommeliers es una colección de vino privada, elaborada gracias a recomendaciones de sumilleres expertos y basada en tecnología Blockchain.</p>
                <p>Nuestros sumilleres, tienen la misión de encontrar los vinos mejor valorados del mercado, para dotar a nuestra colección de una gran calidad, exclusividad y un alto potencial de revalorización.</p>
                <p>Nuestra estrecha relación con los proveedores oficiales de las bodegas y chateaux más codiciados, nos permite acceder a las botellas más exclusivas del mundo.</p>
                <p>Para garantizar la máxima calidad de cada una de nuestras unidades, monitorizamos nuestras botellas para conservarlas siempre a una temperatura y humedad constante en sistemas específicamente diseñados para el vino.</p>
                <p>Nuestra plataforma utiliza tecnología Blockchain para garantizar la autenticidad del vino, su procedencia e implementar la trazabilidad de cada una de nuestras unidades.</p>
                <p>Gracias a la tecnología Blockchain, nuestra plataforma permite a nuestros clientes, comprar, vender e invertir en los vinos más exclusivos y codiciados del mundo de una forma segura y sencilla, para poder disfrutar al máximo de uno de los mejores placeres del mundo.</p>
            </div>
        </div>
    );

};