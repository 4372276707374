"use client";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// THREE
import { group } from '../../Three/nuggets';

// ANTD
import Button from 'antd/es/button';
import { LoadingOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import Modal from 'antd/es/modal/Modal';

// STATE
import { useGlobalState } from '../../GlobalStateProvider/GlobalStateProvider';

// COMPONENTS
import GlbViewer from '../../shared/GlbViewer/GlbViewer';
import Trace from '../../Trace/Trace';

// SERVICES
import { getWineCollection, holdItem, mintCollection, setItemPrice } from '../../shared/services';

// INTERFACES
import { Nft, NftStatus, SetNftPrice, WineCollection } from '../../shared/interfaces';

// STYLES
import './PortfolioItem.scss';

export default function PortfolioItem() {
    
    const location = useLocation();
    const navigate = useNavigate();

    const [state, dispatch] = useGlobalState();

    const [form] = useForm();

    const [wine, setWine] = useState<WineCollection>();
    const [loading, setLoading] = useState<boolean>(true);
    const [priceLoader, setPriceLoader] = useState<boolean>(false);
    const [activeItem, setActiveItem] = useState<Nft>();

    const [sellModalState, setSellModalState] = useState<boolean>(false);
    const [holdModalState, setHoldModalState] = useState<boolean>(false);

    const setWineCollection = async (wineCollectionId: string): Promise<void> => {
        try {

            const wineCollection = await getWineCollection(wineCollectionId);

            setWine(wineCollection);
            setLoading(false);

        } catch (error) {
            
        }
    };

    const setPrice = async(): Promise<void> => {
        try {

            setPriceLoader(true);

            const itemPrice: SetNftPrice = {
                collectionAddress: activeItem?.collectionAddress!,
                email: state.account?.email!,
                price: form.getFieldValue('price'),
                tokenId: activeItem?.tokenId!
            };

            await setItemPrice(itemPrice);

            const nfts: Nft[] = wine!.nfts.map((nft: Nft) => {
                if (nft.tokenId == activeItem?.tokenId) {
                    return  {
                        ...nft,
                        price: form.getFieldValue('price'),
                        status: 'SALE'
                    }
                }
                return nft;
            }) as Nft[];

            setWine({ ...wine!, nfts });
            
            setSellModalState(false);
            setPriceLoader(false);

        } catch (error) {
            
        }
    };

    const hold = async(): Promise<void> => {
        try {

            setPriceLoader(true);

            const itemPrice: SetNftPrice = {
                collectionAddress: activeItem?.collectionAddress!,
                email: state.account?.email!,
                price: 0,
                tokenId: activeItem?.tokenId!
            };

            await holdItem(itemPrice);

            const nfts: Nft[] = wine!.nfts.map((nft: Nft) => {
                if (nft.tokenId == activeItem?.tokenId) {
                    return  {
                        ...nft,
                        price: 0,
                        status: 'HOLD'
                    }
                }
                return nft;
            }) as Nft[];

            setWine({ ...wine!, nfts });

            setHoldModalState(false);
            setPriceLoader(false);

        } catch (error) {
            
        }
    };

    const sellModal = async (nft: Nft): Promise<void> => {
        setActiveItem(nft);
        setSellModalState(true);
    };

    const holdModal = async (nft: Nft): Promise<void> => {
        setActiveItem(nft);
        setHoldModalState(true);
    };

    const order = (nft: Nft): void => {
        dispatch({ cart: [...state.cart, ...[nft]]})
    };

    const redirect = (route: string): void => {
        window.open(route, "_blank")
    };

    const handleSellCancel = (): void =>{
        setSellModalState(false);
    };

    const handleHoldCancel = (): void =>{
        setHoldModalState(false);
    };

    const addToCart = (wine: WineCollection, tokenId: number): void => {        
        dispatch({ cart: [...state.cart, ...[{ ...wine, nfts: wine.nfts.filter((nft: Nft) => nft.tokenId == tokenId) }]] });
    };

    const setStatus = (status: NftStatus): string => {
        switch (status) {
        case NftStatus.MINTED:
            return 'HOLD';
        case NftStatus.HOLD:
            return'HOLD'
        case NftStatus.SALE:
            return'RELEASED'
        case NftStatus.SOLD:
            return'RELEASED'
        default:
            return'HOLD'
        } 
    };

    const trace = (collection: string, tokenId: number): void => {
        dispatch({ trace: { collection, tokenId }});
    };
    
    const mint = async(): Promise<void> => {
        try {
            const mintedCollection = await mintCollection(wine?.address!, state.account?._id!, 6, state.account?.email!);
        } catch (error) {
            
        }
    };

    const removeFromCart = (nft: Nft): void => {
        dispatch({ cart: [...state.cart.filter((cart: WineCollection) => cart.address == nft.collectionAddress && cart.nfts.find((cartNft: Nft) => cartNft.tokenId != nft.tokenId))] });
    };

    useEffect(() => {
        group.visible = false;
        location.pathname && setWineCollection(location.pathname.split('/')[4]);
    }, []);
    
    if (loading) {
        <div className="portfolio-item-container">
            <div className="portfolio-item-content">
                <LoadingOutlined />
            </div>
        </div>
    }

    if (!loading && wine) {
        return (
            <>
                <div className="portfolio-item-container">
                    <div className="portfolio-item-content">
                        {wine?.glb ? (
                            <GlbViewer glbUrl={wine.glb}/>
                        ) : (
                            <div className="wine-image">
                                <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                            </div>
                        )}
                        <div className="wine-info">
                            <div className="wine-details-sheet">
                                <h2>{wine.cellar}</h2>
                                <h1>{wine.name}</h1>    
                                <div>{wine.origin.city}, {wine.origin.country}</div>
                                <div className="description">{wine.description}</div>
                                <div className="wine-details">
                                    <div className="wine-detail">Size: {wine.size} L</div>
                                    <div className="wine-detail">Year: {wine.year}</div>
                                    <div className="wine-detail">Units: {wine.nfts.filter((nft: Nft) => nft.owner._id == location.pathname.split('/')[2]).length}/{wine.nfts.length}</div>
                                    {/* <div className="wine-detail">Production: {wine.production}</div> */}
                                    <div className="wine-detail" onClick={() => redirect(`https://sepolia.etherscan.io/token/${wine.address}`)}>
                                        <span className="address"><QrcodeOutlined /> {`${wine.address.substring(0, 6)}...${wine.address.substring(wine.address.length - 4, wine.address.length)}`}</span>        
                                    </div>
                                </div>
                                <div className="social-container">
                                    <div className="social-avatar">
                                        {wine.creator.avatar ? (
                                            <img src=""/>
                                        ): (
                                            <div className="social-first-letters">{wine.creator.name.substring(0, 1)}</div>
                                        )}
                                    </div>
                                    <div className="social-content">
                                        <span>Created by:</span>
                                        <h5 onClick={() => navigate(`/portfolio/${wine.creator._id}`)}>{wine.creator.name} {wine.creator.lastName}</h5>
                                    </div>
                                </div>
                                {/*
                                    <div className="social-container">
                                        <Sommelier sommelier={wine.sommelier}/>
                                        <Investors investors={wine.investors}/>
                                    </div>
                                */}
                            </div>
                            <div className="positions-container">
                                <div className="positions-content">
                                    <h1>Holdings</h1>
                                    {/* <h1>Holdings {state.account?._id == wine.creator._id && <Button className="mint-items-btn" onClick={() => mint()}>Add new bottles</Button>}</h1> */}
                                    <div className="positions-header">
                                        <div className="position-header-item position-qr">
                                            Id
                                        </div>
                                        <div className="position-header-item status">
                                            Status
                                        </div>
                                        {location.pathname.split('/')[2] == state.account?._id ? (
                                            <div className="position-header-item">
                                                Adq. price
                                            </div>
                                        ): null}
                                        <div className="position-header-item">
                                            Price
                                        </div>
                                        <div className="position-header-item-actions">&nbsp;</div>
                                    </div>
                                    {wine.nfts.filter((nft: Nft) => nft.owner._id == location.pathname.split('/')[2]).map((nft: Nft, i: number) => {
                                        return (
                                            <div className="position-container" key={i}>
                                                <div className="position-content">
                                                    <div className="position-item position-qr" onClick={() => trace(nft.collectionAddress, nft.tokenId)}>
                                                        <QrcodeOutlined />
                                                    </div>
                                                    {nft.owner._id == state.account?._id ? (
                                                        <>
                                                            <div className="position-item status">{setStatus(nft.status)}</div>
                                                            <div className="position-item">{nft.buyPrice ? `${nft.buyPrice} EUR` : '-'}</div>
                                                            <div className="position-item">{nft.status == NftStatus.SALE ? `${nft.price} EUR` : '-'}</div>
                                                            <div className="wine-detail-actions">
                                                                {nft.status == NftStatus.MINTED || nft.status == NftStatus.HOLD ? (
                                                                    <div className="wine-detail">
                                                                        <Button className="sell-btn" onClick={() => sellModal(nft)}>Set Price</Button>
                                                                    </div>
                                                                ) : null}
                                                                {nft.status == NftStatus.SALE && state.account?._id != nft.owner._id ? (
                                                                    <div className="wine-detail">
                                                                        <Button className="sell-btn" onClick={() => addToCart(wine, nft.tokenId)}>Add to cart</Button>
                                                                    </div>
                                                                ): null}
                                                                {nft.status == NftStatus.SALE && state.account?._id == nft.owner._id ? (
                                                                    <div className="wine-detail">
                                                                        <Button className="sell-btn" onClick={() => holdModal(nft)}>Hold</Button>
                                                                    </div>
                                                                ) : null}
                                                                {state.account?._id == nft.owner._id ? (
                                                                    <div className="wine-detail">
                                                                        {state.cart.filter((cart: WineCollection) => cart.address == nft.collectionAddress && cart.nfts.find((cartNft: Nft) => cartNft.tokenId == nft.tokenId)).length > 0 ? (
                                                                            <Button className="sell-btn" onClick={() => removeFromCart(nft)}>Remove</Button>
                                                                        ) : (
                                                                            <Button className="sell-btn" onClick={() => addToCart(wine, nft.tokenId)}>Add to cart</Button>
                                                                        )}
                                                                    </div>
                                                                ): null}
                                                                {nft.status == NftStatus.SOLD ? (
                                                                    <div className="wine-detail">
                                                                        Sold
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="position-item status">{setStatus(nft.status)}</div>
                                                            <div className="position-item">{nft.status == NftStatus.SALE ? `${nft.price} EUR` : '-'}</div>
                                                            <div className="wine-detail-actions">
                                                                {nft.status == NftStatus.SALE ? (
                                                                    <div className="wine-detail">
                                                                        <Button className="sell-btn" onClick={() => addToCart(wine, nft.tokenId)}>Add to cart</Button>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {/*
                                <div className="wine-item-actions">
                                    <Button className="invest-btn" size="large" onClick={() => buy()}>Buy</Button>
                                    <Button className="invest-btn" size="large" onClick={() => invest()}>Invest</Button>
                                    <Button className="invest-btn" size="large" onClick={() => transfer()}>Transfer</Button>
                                </div>
                            */}
                        </div>
                        <div className="trace-info">
                            <Trace collection={location.pathname.split('/')[4]}/>
                        </div>
                    </div>
                </div>
                <Modal title={null} open={sellModalState} onCancel={handleSellCancel}  footer={null} mask={true} centered>
                    <div className="sell-modal-container">
                        <div className="sell-modal-item">
                            <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                            <div className="portfolio-content-label">
                                <h1>{wine?.name}</h1>
                                <h2>{wine?.cellar}</h2>
                                <h5>{wine?.origin.city}, {wine?.origin.country}</h5>
                            </div>
                        </div>
                        <div className="sell-modal-info">
                            <h1>Release your Bottle</h1>
                            <p>Set the price of your bottle to release it into the market. <br/>Anyone in the platform will be able to buy it.</p>
                            <Form form={form} className="" layout="vertical">
                                <Form.Item label="Price of the bottle:" name="price">
                                    <Input size="large"/>
                                </Form.Item>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Button className="sell-btn" size="large" onClick={() => setPrice()} loading={priceLoader} disabled={priceLoader}>Release</Button>
                                    )}
                                </Form.Item>
                            </Form>                
                        </div>
                    </div>
                </Modal>
                <Modal title={null} open={holdModalState} onCancel={handleHoldCancel} footer={null} mask={true} centered>
                    <div className="sell-modal-container">
                        <div className="sell-modal-item">
                            <img src={`${process.env.REACT_APP_BLOB_URL}/${wine.image}`}/>
                            <div className="portfolio-content-label">
                                <h1>{wine?.name}</h1>
                                <h2>{wine?.cellar}</h2>
                                <h5>{wine?.origin.city}, {wine?.origin.country}</h5>
                            </div>
                        </div>
                        <div className="sell-modal-info">
                            <h1>Hold your Bottle</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry  text of the printing.</p>
                            <Button className="sell-btn" size="large" onClick={() => hold()} loading={priceLoader} disabled={priceLoader}>Hold</Button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

};