"use client";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// STATE
import { useGlobalState } from '../../GlobalStateProvider/GlobalStateProvider';

// ANTD
import Button from 'antd/es/button';
import { CloseOutlined, QrcodeOutlined, ShoppingFilled } from '@ant-design/icons';

// SERVICES
import { buyNft, getOwnerByAddress } from '../../shared/services';

// INTERFACES
import { Account, Nft, NftStatus, WineCollection } from '../../shared/interfaces';

// STYLES
import './WineLog.scss';

export default function WineLog({ wine, nft } : { wine: WineCollection, nft: Nft }) {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const setStatus = (status: NftStatus): string => {
        switch (status) {
            case NftStatus.MINTED:
                return 'HOLD';
            case NftStatus.HOLD:
                return'HOLD'
            case NftStatus.SALE:
                return'RELEASED'
            case NftStatus.SOLD:
                return'RELEASED'
            default:
                return'HOLD';
        } 
    };

    const addToCart = (wine: WineCollection, tokenId: number): void => {        
        dispatch({ cart: [...state.cart, ...[{ ...wine, nfts: wine.nfts.filter((nft: Nft) => nft.tokenId == tokenId) }]] });
    };

    const removeFromCart = (tokenId: number): void => {
        dispatch({ cart: [...state.cart.filter((cart: WineCollection) => cart.address == nft.collectionAddress && cart.nfts.find((cartNft: Nft) => cartNft.tokenId != tokenId))] });
    };
    
    const redirect = (route: string): void => {
        window.open(route, "_blank")
    };

    const trace = (collection: string, tokenId: number): void => {
        dispatch({ trace: { collection, tokenId }});
    };

    return (
        <div className="wine-log-container">
            <div className="wine-log-item position-qr" onClick={() => trace(nft.collectionAddress, nft.tokenId)}>
                <QrcodeOutlined/>
            </div>
            <div className="wine-log-item name-item">
                <span className="parisienne-regular" onClick={() => navigate(`/portfolio/${nft?.owner._id}/collection/${wine._id}`)}>
                    {nft?.owner.name} {nft?.owner.lastName}
                </span>
            </div>
            {nft.owner._id != state.account?._id! ? (
                <>
                    <div className="wine-log-item">{setStatus(nft.status)}</div>
                    <div className="wine-log-item">{nft.status == NftStatus.SALE ? `${nft.price} EUR` : '-'}</div>
                    <div className="wine-log-item action">
                        {nft.status == NftStatus.SALE ? (
                            <>
                                {state.cart.filter((cart: WineCollection) => cart.address == nft.collectionAddress && cart.nfts.find((cartNft: Nft) => cartNft.tokenId == nft.tokenId)).length > 0 ? (
                                    <Button className="add-cart-btn" size="large" onClick={() => removeFromCart(nft.tokenId)}>
                                        <span className="add-cart-label">Remove</span>
                                        <span className="add-cart-icon"><CloseOutlined/></span>
                                    </Button>
                                ) : (
                                    <Button className="add-cart-btn" size="large" onClick={() => addToCart(wine, nft.tokenId)}>
                                        <span className="add-cart-label">Add to cart</span>
                                        <span className="add-cart-icon"><ShoppingFilled /></span>
                                    </Button>
                                )}
                            </>
                        ) : '-'}
                    </div> 
                </>
            ) : (
                <>
                    <div className="wine-log-item">{setStatus(nft.status)}</div>
                    <div className="wine-log-item">{nft.status == NftStatus.SALE ? `${nft.price} EUR` : '-'}</div>
                    <div className="wine-log-item action">
                        <Button className="add-cart-btn" size="large" onClick={() => navigate(`/portfolio/${nft?.owner._id}/collection/${wine._id}`)}>View</Button>
                    </div>
                </>
            )}
            {nft.status == NftStatus.SOLD ? <div>Sold</div> : null}
        </div>
    );

}