"use client";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// GOOGLE SIGN IN
import { useGoogleLogin } from '@react-oauth/google';

// ANTD
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Button from 'antd/es/button';
import GoogleOutlined from '@ant-design/icons/lib/icons/GoogleOutlined';

// STATE
import { useGlobalState } from '../GlobalStateProvider/GlobalStateProvider';

// SERVICES
import { loginAccount, loginGoogle } from '../shared/services';

// STYLES
import './Login.scss';

export interface UserLogin {
    email: string;
    password: string;
};

export default function Login() {

    const [state, dispatch] = useGlobalState();
    const [loader, setLoader] = useState<boolean>(false);

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const redirect = () => {
        navigate('/register');
    };

    const login = async () => {

        setLoader(true);

        try {

            const login: UserLogin = {
                email: form.getFieldValue('email'),
                password: form.getFieldValue('password')
            };

            const account = await loginAccount(login);
            localStorage.setItem('token', account.token!);

            dispatch({ account });

            setLoader(false);

            navigate(`/portfolio/${account._id}`);

        } catch (error) {
            localStorage.removeItem('token');
            setLoader(false);
        }

    };
    

    const signInGoogle = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const account = await loginGoogle(codeResponse.access_token);

            localStorage.setItem('token', account.token!);

            dispatch({ account });
            
            navigate(`/portfolio/${account._id}`);
        },
        flow: 'implicit',
    });

    const continueWithoutAccount = () => {
        navigate('/collection');
    }

    return (
        <div className="login-container">
            <div className="login-content">
                <Form className="login-form" form={form} layout="vertical">
                    <h1>Login</h1>
                    <Form.Item label="Email" name="email" required>
                        <Input size="large"/>
                    </Form.Item>
                    <Form.Item label="Password" name="password" required>
                        <Input type="password" size="large"/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <div  className="form-actions">
                                <Button className="cancel-btn" type="primary" onClick={() => redirect()}>
                                    I dont have an account.
                                </Button>
                                <Button className="login-btn" type="primary" onClick={() => login()} loading={loader}>
                                    Login
                                </Button>
                            </div>
                        )}
                    </Form.Item>
                </Form>
                <h1>or</h1>
                <div className="without-account">
                    <Button className="login-btn" type="primary" onClick={() => signInGoogle()}>
                        <GoogleOutlined /> Login with Google
                    </Button>
                    <Button className="ant-btn" type="primary" onClick={() => continueWithoutAccount()}>
                        Continue without account
                    </Button>
                </div>
            </div>
        </div>
    );

};